import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

/* tslint:disable:no-string-literal */

export interface FormData {
	name: string;
	email: string;
	phone: string;
	message: string;
	type: string;
	city: string;
}


@Injectable({
	providedIn: 'root',
})
export class ContactFormService {

	constructor(
		protected api: ApiService,
	) {
	}

	send(data: FormData): Promise<string> {

		if (window['gtag']) {
			window['gtag']('event', 'contact_form_sent');
		}

		if (window['fbq']) {
			window['fbq']('track', 'Lead');
		}

		return this.api.callPhpApi(
			'send-form.php',
			data,
		);

	}

}
